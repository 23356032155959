<template lang="pug">
.drawLine
</template>

<script>
  export default {};
</script>

<style lang="scss">
  .drawLine {
    background: #ededed;
    transform: scale(0);
    &._active {
      animation-duration: 1.2s;
      animation-timing-function: normal;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
      // background: red;
    }
    &._x {
      width: 100%;
      height: 2px;

      // temp
      transform-origin: left center;
      &._active {
        animation-name: drawLineX;
      }
    }
    &._y {
      height: 100%;
      width: 2px;
      transform-origin: center top;
      // transform: scaleY(0);
      &._active {
        animation-name: drawLineY;
      }
    }
  }

  @keyframes drawLineX {
    0% {
      transform: scaleX(0);
    }
    100% {
      transform: scaleX(1);
    }
  }
  @keyframes drawLineY {
    0% {
      transform: scaleY(0);
    }
    100% {
      transform: scaleY(1);
    }
  }
</style>
