<template lang="pug">
main.alertPage
  section.alertPage-logo: router-link.tabletNone(:to='{ name: "HomePage" }'): SvgComponent(name='logo')
  section.alertPage-title
    router-link.phoneOnly(:to='{ name: "HomePage" }'): SvgComponent(name='logo')
    h2(:class='success ? "green" : "red"') {{ success ? "Thank you!" : "404" }}
  section.alertPage-content
    .alertPage-subtitle(
      v-html='success ? "We will contact <br>you shortly" : "This page doesn’t exist"'
    )
    .alertPage-return.curp(@click='success ? $router.go(-1) : $router.push({ name: "HomePage" })')
      span.red(v-if='success') Return back
      span(v-else) Go to <span class="red">homepage</span>
      .bounce: SvgComponent.red(name='arrow')
  DrawLine._x._1._active
  DrawLine._y._2._active
  DrawLine._y._3._active
</template>

<script>
  import DrawLine from '@/components/DrawLine.vue';

  export default {
    data() {
      return {
        success: this.$route.meta.type == 'success',
      };
    },
    components: {
      DrawLine,
      SvgComponent: () => import('@/components/SvgComponent'),
    },
    mounted() {
      this.$store.commit('pageLoaded');
      document.querySelector('#app').style.overflowY = 'visible';
      document.body.style.overflowY = 'visible';
    },
  };
</script>

<style lang="scss">
  .alertPage {
    .drawLine {
      &._1 {
        grid-row: 2;
        grid-column: left-side / right-side;
      }
      &._2 {
        grid-row: 1 / 3;
        grid-column: 4;
      }
      &._3 {
        grid-row: 1 / 3;
        grid-column: 12;
      }
    }
  }
  .alertPage-title {
    grid-row: 1;
    grid-column: 4 / 12;
    // background: red;
    // text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35vh;
    @include tablet {
      flex-direction: column;
      text-align: center;
      .svg-logo {
        color: #cecece;
        transform: scale(0.6);
        transform-origin: center top;
      }
    }
    h2 {
      font-size: 64px;
      @include tablet {
        font-weight: bold;
        font-size: 36px;
        line-height: 43px;
        margin-top: 20px;
      }
    }
  }
  .alertPage-content {
    grid-row: 2;
    grid-column: 4 / 12;
    // background: green;
    text-align: center;
    display: flex;
    height: 65vh;
    align-items: center;
    flex-direction: column;
    justify-content: space-evenly;
  }
  .alertPage-logo {
    padding-top: 50px;
    // align-items: center;
    grid-row: 1;
    grid-column: left-side / 4;
    // background: blue;
    display: flex;
    justify-content: center;
    color: #cecece;
  }
  .alertPage-subtitle {
    font-weight: bold;
    font-size: 46px;
    font-family: 'Proto Grotesk Web';
    line-height: 55px;
    @include tablet {
      font-size: 30px;
      line-height: 36px;
      padding: 0 40px;
    }
  }
  .alertPage-return {
    font-weight: normal;
    font-size: 28px;
    line-height: 34px;
    font-family: 'Proto Grotesk Web';
    display: flex;
    align-items: center;
    .svg-arrow {
      transform: scaleX(-1);
      margin-left: 20px;
    }
    @include tablet {
      font-size: 24px;
      line-height: 29px;
    }
  }
</style>
